<template>
  <div class="fqa-item">
    <div class="fqa-item__question" v-on:click="open">
      <div class="fqa-item__question__q">Q</div>
      <div class="fqa-item__question__question">
        <slot name="question"></slot>
      </div>
      <plus-icon class="fqa-item__question__plus" :active="isOpen" />
    </div>
    <collapse-transition :duration="300">
      <div v-show="isOpen" class="fqa-item__answer">
        <div class="fqa-item__answer__a">A</div>
        <div class="fqa-item__answer__answer">
          <slot name="answer"></slot>
        </div>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import { CollapseTransition } from "@ivanv/vue-collapse-transition";
import PlusIcon from "@/components/atoms/PlusIcon.vue";

export default {
  components: {
    CollapseTransition,
    PlusIcon,
  },
  data: function () {
    return {
      isOpen: false,
    };
  },
  methods: {
    open: function () {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.fqa-item {
  border: 1rem solid #cbcbcb;
  background: #ffffff;
  border-radius: 20rem;
  &__question {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16rem 40rem;
    @include mq(xs) {
      padding: 16rem 20rem;
    }
    &__q {
      @extend .fs--xxl;
      font-weight: 700;
      line-height: 22rem;
    }
    &__question {
      @extend .fs--xl;
      font-weight: 500;
      letter-spacing: 1.1rem;
      margin-left: 27rem;
      margin-right: 27rem;
      flex-grow: 2;
      text-align: left;
      @include mq(xs) {
        font-size: calc(#{$regular-font-size} * 1.125); // 16pxの場合: 18px
      }
    }
    &__plus {
      flex: 0 0 20rem;
      width: 20rem;
      height: 20rem;
    }
  }
  &__answer {
    border-top: 1rem solid #cbcbcb;
    display: flex;
    padding: 16rem 40rem 30rem 40rem;
    @include mq(md) {
      padding: 16rem 20rem;
    }
    @include mq(xs) {
      padding: 16rem 20rem;
    }
    &__a {
      @extend .fs--xxl;
      font-weight: 700;
    }
    &__answer {
      margin-left: 27rem;
      text-align: left;
      @extend .fs--r;
      letter-spacing: 0.75rem;
      line-height: 2;
      @include mq(xs) {
      }
    }
  }
}
</style>