<template>
  <aside class="side-bar">
    <h2 class="side-bar__title">{{ title }}</h2>
    <div class="side-bar__sections">
      <section
        class="side-bar__sections__section"
        v-for="(section, i) in sections"
        :key="i"
      >
        <h3 class="side-bar__sections__section__title">
          {{ section.name }}
        </h3>
        <div class="side-bar__sections__section__items">
          <router-link
            class="side-bar__sections__section__items__item"
            :class="{
              'side-bar__sections__section__items__item--active': isActive(
                item.link
              ),
            }"
            v-for="(item, i) in section.items"
            :key="i"
            :to="item.link"
            >{{ item.name }}</router-link
          >
        </div>
      </section>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    title: String,
    sections: Array,
  },
  methods: {
    isActive: function (link) {
      return link === this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  height: auto;
  background: #ffffff;
  box-shadow: 0rem 3rem 8rem #00000014;
  border-radius: 14rem;
  padding: 27rem 27rem;
  text-align: left;
  &__title {
    letter-spacing: 0.85rem;
    text-align: center;
    color: #444455;
    @extend .fs--l;
    font-weight: 700;
  }
  &__sections {
    margin-bottom: -6rem;
    @include mq(lg) {
      display: flex;
      justify-content: space-around;
    }
    @include mq(xs) {
      display: block;
    }
    &__section {
      letter-spacing: 0.75rem;
      color: #444455;
      margin-top: 24rem;
      &__title {
        @extend .fs--r;
        font-weight: 700;
      }
      &__items {
        margin-top: 6rem;
        &__item {
          text-decoration: none;
          display: block;
          color: #676767;
          @extend .fs--r;
          font-weight: 500;
          letter-spacing: 0.7rem;
          padding: 6rem 15rem 6rem 15rem;
          &--active {
            color: #ff8546;
          }
          &:hover {
            color: #ff8546;
          }
        }
      }
    }
  }
}
</style>
