<template>
  <section class="customer">
    <Frame class="customer__container">
      <section-title class="customer__title"
        >CUSTOMER<template v-slot:subtitle>導入実績</template></section-title
      >
      <div class="customer__customers">
        <TitleImageCard
          v-for="(customer, i) in customers"
          :key="i"
          :image="customer.image"
          :link="customer.link"
          class="customer__card"
          ><template v-slot:title>{{ customer.name }}</template
          >{{ customer.text }}
        </TitleImageCard>
      </div>
    </Frame>
  </section>
</template>

<script>
import SectionTitle from "@/components/atoms/SectionTitle.vue";
import Frame from "@/components/contents/Frame.vue";
import TitleImageCard from "@/components/molecules/TitleImageCard.vue";

export default {
  components: {
    SectionTitle,
    Frame,
    TitleImageCard,
  },
  data: function() {
    return {
      customers: [
        {
          name: "株式会社コロプラ",
          text: `Stena Gameのログ分析を活かして不正対策に取り組むコロプラ様との対談インタビューがgamebizに掲載されました。`,
          image: "customer_colopl.png",
          link: "https://gamebiz.jp/news/287149",
        },
        {
          name: "株式会社gumi",
          text: `「誰ガ為のアルケミスト」へStena Gameのログ分析を活かして不正対策に取り組むgumi様との対談インタビューがgamebizに掲載されました。`,
          image: "customer_gumi.jpeg",
          link: "https://gamebiz.jp/news/345222",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.customer {
  background: #f4f5f6;

  &__container {
    @include mq(xl) {
    }
  }

  &__title {
    margin: auto;
    margin-bottom: 60rem;

    @include mq(xl) {
      width: 1174rem;
    }
  }

  &__customers {
    display: flex;
    justify-content: center;

    @include mq(md) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__card + &__card {
    margin-left: 30rem;

    @include mq(md) {
      margin-left: 0rem;
      margin-top: 30rem;
    }
  }
}
</style>
