<template>
  <detail :topTitle="topTitle" :title="title">
    <div class="detail-content__sections__section">
      <h2 class="detail-content__sections__section__title">不正スコアの分布</h2>
      <p class="detail-content__sections__section__text">
        Stena Gameでは全ユーザの不正スコア分布をグラフで確認することができます。
        ゲーム全体で明らかに不正なユーザやグレーなユーザがどれくらいいるのかの確認や、今までと不正の傾向がどう変わっているかを把握することにお役立ていただけます。
      </p>
      <img class="detail-content__sections__section__image" src="@/assets/analyze_ui.svg" decoding="async" />
    </div>
    <div class="detail-content__sections__section">
      <h2 class="detail-content__sections__section__title">
        グラフをクリックして選択したスコア帯のユーザを一覧表示
      </h2>
      <p class="detail-content__sections__section__text">
        グラフ内の棒をクリックすることで、その不正スコア帯の不正ユーザを表示するテーブルが面に遷移し、全体傾向から不正ユーザ詳細の詳細までドリルダウンで確認することができます。
      </p>
      <img class="detail-content__sections__section__image" src="@/assets/analyze_sidebar_ui.png" decoding="async" />
    </div>
  </detail>
</template>

<script>
import Detail from "@/pages/Detail.vue";
export default {
  components: { Detail },
  data: function () {
    return {
      topTitle: "不正傾向の分析",
      title: "ゲーム全体の不正傾向を確認",
    };
  },
};
</script>

<style lang="scss" scoped>
.detail-content__sections__section {
  &:last-child {
    margin-bottom: 60rem;
  }
}
</style>