<template>
  <img :src="loadedImage" decoding="async" />
</template>

<script>
export default {
  props: {
    src: { type: String, require: true },
  },
  computed: {
    loadedImage() {
      return require(`@/assets/${this.src}`);
    },
  },
};
</script>

<style>
</style>