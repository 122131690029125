<template>
  <div class="vartical-card">
    <underline-title textAlign="center" fontSize="22rem"
      ><slot name="title"></slot
    ></underline-title>
    <props-image class="vartical-card__image" :src="image" />
    <div class="vartical-card__text"><slot></slot></div>
  </div>
</template>

<script>
import PropsImage from "../atoms/PropsImage.vue";
import UnderlineTitle from "../atoms/UnderlineTitle.vue";
export default {
  components: { UnderlineTitle, PropsImage },
  props: {
    image: { type: String, require: true },
  },
};
</script>

<style lang="scss" scoped>
.vartical-card {
  width: 360rem;
  box-shadow: 0rem 3rem 8rem #00000014;
  background: #ffffff;
  border-radius: 10rem;
  padding: 30rem 40rem;
  &__title {
  }
  &__image {
    margin-top: 30rem;
    max-width: 100%;
  }
  &__text {
    @extend .fs--r;
    text-align: left;
    color: #444455;
    letter-spacing: 0.75rem;
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 1.8;
    margin-top: 30rem;
    @include mq(lg) {
      margin-top: 30rem;
    }
  }
}
</style>