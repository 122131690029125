<template>
  <detail :topTitle="topTitle" :title="title">
    <div class="detail-content__sections__section">
      <h2 class="detail-content__sections__section__title">
        不正ユーザ一覧表示
      </h2>
      <p class="detail-content__sections__section__text">
        検知された不正ユーザはテーブルに一覧表示され、ブラウザからいつでも確認することができます。
        不正の度合いを表す不正スコアや日付での並べ替えやユーザ検索で特定のユーザのみを表示する機能を備えているほか、ある不正項目に該当したユーザが他の不正項目にも該当しているかもすぐに比較することができます。
        また、検知されたユーザの過去の不正履歴もすぐに確認することができるため、何度も不正を繰り返すユーザを一目で把握することができます。
      </p>
      <img class="detail-content__sections__section__image" src="@/assets/aggregate_ui.svg" decoding="async" />
    </div>
    <div class="detail-content__sections__section">
      <h2 class="detail-content__sections__section__title">
        不正スコアでユーザの悪質さを表現
      </h2>
      <p class="detail-content__sections__section__text">
        Stena Gameは全てのユーザに対して不正の度合いをスコアリングし、重度の高い不正なのか不正に近いグレーなのかを0～100%の値でお知らせするため、明らかな不正なのか、グレーに近い不正なのかを確認することができます。
        不正スコアが一定以上でのユーザをBANするなど、ユーザ処置自動化の指標としてもご利用いただけます。
      </p>
    </div>
  </detail>
</template>

<script>
import Detail from "@/pages/Detail.vue";
export default {
  components: { Detail },
  data: function () {
    return {
      topTitle: "不正ユーザの集計",
      title: "不正ユーザを日ごとに集計",
    };
  },
};
</script>

<style lang="scss" scoped>
</style>