import API from "@/pages/API.vue";
import BlockFalsePositive from "@/pages/BlockFalsePositive.vue";
import Contact from "@/pages/Contact.vue";
import InputLog from "@/pages/InputLog.vue";
import Main from "@/pages/Main.vue";
import MalAnalyze from "@/pages/MalAnalyze.vue";
import MalUserAggregate from "@/pages/MalUserAggregate.vue";
import ModelKind from "@/pages/ModelKind.vue";
import ModelUpdate from "@/pages/ModelUpdate.vue";
import PostMeasureEffect from "@/pages/PostMeasureEffect.vue";
import paths from "@/router/paths.js";
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const DEFAULT_TITLE = "Stena Game | チート・BOT自動検知システム";

const router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    const isJumpToID = to.path === "/" && from.path === "/";
    if (isJumpToID || savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: Main,
      meta: { title: DEFAULT_TITLE },
    },
    {
      path: "/contact",
      component: Contact,
      meta: { title: "Stena Game | お問い合わせ" },
    },
    {
      path: paths.featureEffectPath,
      component: PostMeasureEffect,
      meta: { title: "Stena Game | 事後対策の効果" },
    },
    {
      path: paths.featureModelPath,
      component: ModelKind,
      meta: { title: "Stena Game | 検知モデルの種類" },
    },
    {
      path: paths.featureUpdatePath,
      component: ModelUpdate,
      meta: { title: "Stena Game | モデルアップデート" },
    },
    {
      path: paths.managerAggregatePath,
      component: MalUserAggregate,
      meta: { title: "Stena Game | 不正ユーザの集計" },
    },
    {
      path: paths.managerAnalyzePath,
      component: MalAnalyze,
      meta: { title: "Stena Game | 不正傾向の分析" },
    },
    {
      path: paths.operationLogPath,
      component: InputLog,
      meta: { title: "Stena Game | ログの入力と形式" },
    },
    {
      path: paths.operationApiPath,
      component: API,
      meta: { title: "Stena Game | 提供API" },
    },
    {
      path: paths.operationSafePath,
      component: BlockFalsePositive,
      meta: { title: "Stena Game | 誤検知対策" },
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});

router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});

export default router;
