<template>
  <detail :topTitle="topTitle" :title="title">
    <div class="detail-content__sections__section">
      <h2 class="detail-content__sections__section__title">
        ゲームの仕様変化と検知精度への影響
      </h2>
      <p class="detail-content__sections__section__text">
        ゲーム運営では、新規機能の追加や各種調整により、仕様が変更するケースが多くあります。事前に不正のルールを設定しても、仕様や遊び方が変化することで不正ルールを再設定する必要が生まれてしまいます。
      </p>
      <img class="detail-content__sections__section__image" src="@/assets/update_update.svg" decoding="async" />
    </div>
    <div class="detail-content__sections__section">
      <h2 class="detail-content__sections__section__title">
        検知モデルのアップデート
      </h2>
      <p class="detail-content__sections__section__text">
        Stena Gameの検知モデルは、運用中に蓄積されたデータや直近のデータを用いて再学習を行うことで短期間のうちにモデルアップデートをすることができます。
        これにより、ゲームの仕様変化で検知精度に影響が出る場合でもすぐに新しい環境に合わせた不正検知を行うことができます。モデルのアップデートはStena Gameの運用サポートで承っております。
      </p>
    </div>
  </detail>
</template>

<script>
import Detail from "@/pages/Detail.vue";
export default {
  components: { Detail },
  data: function () {
    return {
      topTitle: "モデルアップデート",
      title: "ゲーム仕様が変化しても高い精度で検知",
    };
  },
};
</script>

<style lang="scss" scoped>
</style>