<template>
  <base-button
    class="square-button"
    :color="color"
    :background="background"
    :linkTo="linkTo"
    ><slot></slot
  ></base-button>
</template>

<script>
import BaseButton from "@/components/atoms/BaseButton.vue";

export default {
  components: { BaseButton },
  props: {
    color: { type: String, default: "white" },
    background: { type: String, default: "#2bba2f" },
    linkTo: { type: String, default: "/" },
  },
};
</script>

<style scoped lang="scss">
.square-button {
  width: 240rem;
  height: 50rem;
  font-weight: 700;
  box-shadow: 0rem 3rem 10rem #00000029;
  letter-spacing: 0.7rem;
  &:hover {
    transition: all 200ms 0s ease;
    box-shadow: 0rem 3rem 5rem #00000029;
  }
}
</style>