<template>
  <router-link tag="button" :to="linkTo" class="base-button" :style="style"
    ><slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    color: { type: String, default: "white" },
    background: { type: String, default: "#ff2424" },
    linkTo: { type: String, default: "/" },
  },
  computed: {
    style() {
      return {
        "--color": this.color,
        "--background": this.background,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.base-button {
  background: var(--background);
  color: var(--color);
  outline: none;
  &:hover {
    transition: all 200ms 0s ease;
    opacity: 0.8;
  }
}
</style>