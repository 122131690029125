import firebase from "firebase/app";
import 'firebase/analytics';
import VeeValidate from "vee-validate";
import Vue from "vue";
import VueObserveVisibility from "vue-observe-visibility";
import App from "./App.vue";
import router from "./router";
import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.use(VueObserveVisibility);
Vue.use(VeeValidate);
Vue.use(VueSmoothScroll);

var firebaseConfig = {
  apiKey: "AIzaSyAVkkZItyQkyrSmKzmyr-k4adS2rNjXdIg",
  authDomain: "stena.chillstack.com",
  databaseURL: "https://stena-lp.firebaseio.com",
  projectId: "stena-lp",
  storageBucket: "stena-lp.appspot.com",
  messagingSenderId: "825449593859",
  appId: "1:825449593859:web:6eb8433747e4f50d5ea236",
  measurementId: "G-5SCECT9XH5",
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
