<template>
  <section id="contact" class="pb-5">
    <Frame>
      <div id="container">
        <h1>CONTACT</h1>

        <div v-if="didSend">
          <p id="thank">
            お問い合わせありがとうございます。
            <br />折り返しご連絡いたしますので、しばらくお待ちください。
          </p>
        </div>

        <div v-else>
          <p class="describe">
            Stena Gameについてのお問い合わせは下記フォームよりお願いいたします。
          </p>
          <p class="describe--notes notes text-center">
            ※現在お問い合わせ多数につき、ご返信にお時間をいただく場合がございますのでご了承ください。
          </p>
          <div id="subject">
            <div class="flex-column d-inline-flex">
              <h2 id="step2" class="mb-4">お問い合わせ項目</h2>
              <div class="text-left">
                <div class="custom-control custom-radio mb-2">
                  <input @focus="clickSmoothScroll2" v-model="contactForm.selectedButton" value="purchase" type="radio"
                    id="purchase" name="subject" class="custom-control-input mb-2" />
                  <label class="custom-control-label" for="purchase">Stena Game導入のご検討</label>
                </div>
                <div class="custom-control custom-radio mb-2">
                  <input @focus="clickSmoothScroll2" v-model="contactForm.selectedButton" value="free-trial"
                    type="radio" id="free-trial" name="subject" class="custom-control-input mb-2" />
                  <label class="custom-control-label" for="free-trial">ログ分析お試しサービスのご依頼</label>
                </div>
                <div class="custom-control custom-radio mb-2" id="step3">
                  <input @focus="clickSmoothScroll2" v-model="contactForm.selectedButton" value="else" type="radio"
                    id="else" name="subject" class="custom-control-input mb-2" />
                  <label class="custom-control-label" for="else">その他</label>
                </div>
              </div>
            </div>
          </div>
          <div v-if="contactForm.selectedButton !== ''" class="fade">
            <div id="contact-content" class="p-5">
              <form @submit.prevent="validateForm" data-vv-scope="all">
                <h2 class="mb-5">お問い合わせ内容</h2>
                <form-item label="貴社名" :required="true" placeholder="株式会社ChillStack" v-model="contactForm.companyName"
                  v-validate="'required'">
                </form-item>
                <div class="separator"></div>
                <form-item label="部署名" placeholder="開発部" :required="false" v-model="contactForm.department">
                </form-item>
                <div class="separator"></div>
                <form-item label="お名前" placeholder="山田 太郎" :required="true" v-model="contactForm.firstName"
                  v-validate="'required'">
                </form-item>
                <div class="separator"></div>
                <form-item label="メールアドレス" placeholder="abc@chillstack.com" :required="true" v-model="contactForm.email"
                  v-validate="'required|email'">
                </form-item>
                <div class="separator"></div>
                <form-item label="内容詳細" :required="false" placeholder="入力してください" v-model="contactForm.content"
                  type="textarea" id="content">
                </form-item>
                <div class="separator p-bottom"></div>
                <p v-if="isError" class="alert alert--danger my-4">
                  必須項目を入力してください
                </p>
                <button v-bind:disabled="!buttonEnable" class="contact-button">
                  送信する
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Frame>
  </section>
</template>

<script>
import Frame from "@/components/contents/Frame.vue";
import firebase from "firebase/app";
import "firebase/functions";
import FormItem from "../components/molecules/FormItem.vue";

export default {
  name: "Contact",
  components: {
    Frame,
    FormItem,
  },
  data: () => ({
    contactForm: {
      selectedButton: "",
      companyName: "",
      department: "",
      firstName: "",
      lastName: "",
      email: "",
      content: "",
    },
    isLoading: false,
    buttonEnable: true,
    isError: false,
    didSend: false,
  }),
  methods: {
    validateForm() {
      this.$validator.validateAll("all").then((result) => {
        if (!result) {
          this.isError = true;
          return;
        }
        this.isError = false;
        this.sendForm();
      });
    },
    sendForm() {
      this.isLoading = true;
      // firebase config の authDomainのホストからfunctionを実行しないとCORSエラーが起こる
      const sendForm = firebase.functions().httpsCallable("sendForm");
      sendForm(this.contactForm)
        .then(() => {
          this.didSend = true;
        })
        .catch(() => {
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    clickSmoothScroll2() {
      event.preventDefault();
      this.$smoothScroll({
        scrollTo: document.querySelector("#step3"),
        duration: 1000,
        easingFunction: (t) =>
          t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t,
      });
    },
  },
  watch: {
    isLoading() {
      this.buttonEnable = !this.isLoading;
    },
  },
};
</script>

<style scoped lang="scss">
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}

.d-inline-flex {
  display: inline-flex;
}

h2 {
  @extend .fs--xxxl;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 24rem;
  padding-left: 24rem;
  @extend .fs--l;
}

.custom-radio {}

.custom-control-input {
  position: absolute;
  left: 0;
  top: 4rem;
  width: 16rem;
  height: 20rem;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  @extend .fs--l;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.my-4 {
  margin-top: calc(16rem * 1.5);
  margin-bottom: calc(16rem * 1.5);
}

.mb-2 {
  margin-bottom: calc(16rem * 0.5);
}

.mb-4 {
  margin-bottom: calc(16rem * 1.5);
}

.mb-5 {
  margin-bottom: calc(16rem * 3);
}

.p-5 {
  padding: calc(16rem * 3);
}

.alert {
  position: relative;
  padding: 12rem 20rem;
  border: 1px solid transparent;
  border-radius: 4rem;

  &--danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}

#contact {
  background-color: #f2f2f2;
  @extend .fs--l;
  min-height: calc(100vh - 90rem);
  padding-top: 60rem;
}

h1 {
  @extend .fs--xxxxl;
}

#thank {
  font-weight: 500;
  margin-top: 30rem;
  font-size: 25rem;
}

.describe {
  margin-top: 24rem;
  margin-bottom: 4rem;
  @extend .fs--xl;

  &--notes {
    margin-bottom: 48rem;
  }
}

#subject {
  background-color: white;
  border-radius: 14rem;
  padding: 48rem;
  margin-bottom: 48rem;
}

#contact-content {
  background-color: white;
  border-radius: 14rem;
}

#content {
  height: 200rem;
  resize: none;
}

h2 {
  font-weight: bold;
  font-size: 26rem;
}

.separator {
  background-color: gray;
  height: 1rem;
}

.contact-label {
  text-align: left;
}

.fade {
  animation-name: fadein;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(20rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.p-bottom {
  margin-bottom: 40rem;
}

.notes {
  @extend .fs--r;
  text-align: center;
  color: #969696;
}

.contact-button {
  padding: 10rem 40rem;
  @extend .fs--r;
  box-shadow: 0rem 3rem 10rem #00000029;
  font-weight: 500;
  color: white;
  background: #ff8546;
  border: 0;
}

.contact-button:hover {
  transition: all 200ms 0s ease;
  opacity: 0.8;
  box-shadow: 0rem 3rem 5rem #00000029;
}

.contact-button:disabled {
  opacity: 0.6;
}

button:focus {
  outline: 0;
}
</style>
