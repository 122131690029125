<template>
  <main class="detail-content">
    <fade>
      <div class="detail-content__top-title">
        <slot name="topTitle"></slot>
      </div>
      <underline-title fontSize="22rem" class="detail-content__title"
        ><slot name="title"></slot
      ></underline-title>
      <div class="detail-content__sections">
        <slot></slot>
      </div>
    </fade>
  </main>
</template>

<script>
import UnderlineTitle from "@/components/atoms/UnderlineTitle.vue";
import Fade from "@/components/contents/FadeAnimation.vue";

export default {
  components: {
    UnderlineTitle,
    Fade,
  },
};
</script>

<style lang="scss">
.detail-content {
  background: #ffffff;
  box-shadow: 0rem 3rem 8rem #00000014;
  border-radius: 14rem;
  padding: 0rem 80rem;
  @include mq(sm) {
    padding: 0rem 40rem;
  }
  @include mq(xs) {
    padding: 0rem 10rem;
  }
  &__top-title {
    @extend .fs--r;
    font-weight: 700;
    letter-spacing: 0.7rem;
    color: #444455;
    text-align: center;
    padding-top: 30rem;
    padding-bottom: 60rem;
  }
  &__title {
    @extend .fs--xl;
    letter-spacing: 1.1rem;
    font-weight: 700;
  }
  &__sections {
    margin-top: 60rem;
    padding: 0rem 30rem;
    @include mq(xs) {
      padding: 0rem 10rem;
    }
    &__section {
      margin-bottom: 90rem;
      &:last-child {
        margin-bottom: 0rem;
      }
      &__title {
        @extend .fs--l;
        font-weight: 700;
        text-align: left;
        letter-spacing: 0.9rem;
        color: #444455;
      }
      &__text {
        @extend .fs--r;
        text-align: left;
        line-height: 2.142857143;
        letter-spacing: 0.7rem;
        color: #444455;
        margin-top: 30rem;
        margin-bottom: 60rem;
      }
      &__image {
        max-width: 100%;
      }
    }
  }
}
</style>