<template>
  <section class="usage-flow">
    <Frame class="usage-flow__container">
      <section-title>STEP<template v-slot:subtitle>導入ステップ</template></section-title>
      <div class="usage-flow__steps">
        <div v-for="(step, i) in steps" :key="i" :class="stepClass(step)">
          <top-image-card-with-number v-if="isCard(step)" :image="step.image" :number="Math.ceil((i + 1) / 2)"><template
              v-slot:title>{{ step.title }}</template>{{ step.content }}</top-image-card-with-number>
          <triangle-arrow v-else></triangle-arrow>
        </div>
        <round-box class="usage-flow__steps__effort usage-flow__steps__effort--first">1～2週間</round-box>
        <round-box class="usage-flow__steps__effort usage-flow__steps__effort--second">1～2週間</round-box>
        <round-box class="usage-flow__steps__effort usage-flow__steps__effort--third">永続</round-box>
      </div>

      <div class="usage-flow__contact">
        <div class="usage-flow__contact__text--small">
          まず自社ゲームタイトルに不正ユーザがどれくらいいるのか確認したい方は
        </div>
        <div class="usage-flow__contact__text">
          不正ユーザを発見できるログ分析お試しサービスも実施しております。
        </div>
        <square-button class="usage-flow__contact__button" background="#FF8546" linkTo="/contact">利用を申し込む
        </square-button>
      </div>
    </Frame>
  </section>
</template>

<script>
import RoundBox from "@/components/atoms/RoundBox.vue";
import SectionTitle from "@/components/atoms/SectionTitle.vue";
import SquareButton from "@/components/atoms/SquareButton.vue";
import TriangleArrow from "@/components/atoms/TriangleArrow.vue";
import Frame from "@/components/contents/Frame.vue";
import TopImageCardWithNumber from "@/components/molecules/TopImageCardWithNumber.vue";

export default {
  components: {
    Frame,
    SectionTitle,
    TopImageCardWithNumber,
    TriangleArrow,
    RoundBox,
    SquareButton,
  },
  data: function () {
    return {
      steps: [
        {
          title: "ご相談・お見積り",
          image: "step_mtg.png",
          content:
            "不正検知項目数やStena Gameの動作環境のご希望をもとに検知モデルの構成や料金ベースラインをご案内いたします。",
        },
        {}, // 矢印
        {
          title: "事前ログ分析",
          image: "step_analyze.png",
          content:
            "NDA締結後、お客様サービスのログサンプルをご提供いただき、不正検知に必要な情報の有無や形式を確認いたします。",
        },
        {}, // 矢印
        {
          title: "AI学習",
          image: "step_ai.png",
          content:
            "事前にいただいたデータを用いて不正検知AIを作成いたします。 精度向上のため追加のデータ提供をお願いする場合があります。",
        },
        {}, // 矢印
        {
          title: "Stena Game導入",
          image: "step_cloud.png",
          content:
            "AIモデルを搭載したStena Gameを納品いたします。クラウドの場合は即座に、オンプレの場合は貴社にお伺いし導入いたします。",
        },
        {}, // 矢印
        {
          title: "運用",
          image: "step_hands.png",
          content:
            "新たな不正発見のための新規モデル作成や、運用中モデルの再学習サポートを承ります。",
        },
      ],
    };
  },
  methods: {
    isCard: function (step) {
      return "title" in step;
    },
    stepClass: function (step) {
      return "usage-flow__steps__" + (this.isCard(step) ? "step" : "arrow");
    },
  },
};
</script>

<style scoped lang="scss">
.usage-flow {
  background: #ffffff;

  &__steps {
    display: grid;
    grid-template-rows: 1fr 43rem;
    grid-template-columns: 186rem repeat(4, auto 186rem);
    row-gap: 30rem;
    justify-content: space-around;
    margin-top: 60rem;
    padding-bottom: 120rem;

    @include mq(lg) {
      grid-template-columns: 168rem repeat(4, auto 168rem);
    }

    @include mq(md) {
      display: block;
    }

    &__step {}

    &__arrow {
      align-self: flex-end;
      height: 193rem;
      padding: auto;

      @include mq(md) {
        transform: rotate(90deg);
        height: 30rem;
        width: 30rem;
        margin: auto;
        margin-top: 30rem;
      }
    }

    &__effort {
      grid-row: 2;

      @include mq(md) {
        display: none;
      }

      &--first {
        grid-column: 1/4;
      }

      &--second {
        grid-column: 5/8;
      }

      &--third {
        grid-column: 9;
      }
    }
  }

  &__contact {
    &__text {
      color: #444455;
      letter-spacing: 1.2rem;
      @extend .fs--xxl;
      font-weight: 500;
      margin-bottom: 40rem;

      @include mq(xs) {
        font-size: 20rem;
      }

      &--small {
        @extend .fs--r;
        letter-spacing: 0.7rem;

        @include mq(xs) {
          margin-bottom: 16rem;
        }
      }
    }

    &__button {
      display: inline-block;
      text-align: center;
      @extend .fs--l;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
