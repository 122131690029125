<template>
  <div class="bar">
    <nav class="bar-wrap">
      <div class="bar-wrap__left">
        <a class="bar-wrap__left__brand" href="/" @click="clickSmoothScroll('#top')">Stena Game</a>
      </div>
      <div class="bar-wrap__right">
        <div v-show="isTop" class="bar-wrap__right__sections bar-wrap__right__sections--hamburger" :class="{
          'bar-wrap__right__sections--hamburger--active': isHamburgerOpen,
        }">
          <a href="#about" @click="clickSmoothScroll('#about')" class="
              bar-wrap__right__sections__section
              bar-wrap__right__sections__section--hamburger-item
            ">機能</a>
          <a href="#feature" @click="clickSmoothScroll('#feature')" class="
              bar-wrap__right__sections__section
              bar-wrap__right__sections__section--hamburger-item
            ">特徴</a>
          <a href="#customer" @click="clickSmoothScroll('#customer')" class="
              bar-wrap__right__sections__section
              bar-wrap__right__sections__section--hamburger-item
            ">導入実績</a>
          <a href="#step" @click="clickSmoothScroll('#step')" class="
              bar-wrap__right__sections__section
              bar-wrap__right__sections__section--hamburger-item
            ">導入ステップ</a>
          <a href="#faq" @click="clickSmoothScroll('#faq')" class="
              bar-wrap__right__sections__section
              bar-wrap__right__sections__section--hamburger-item
            ">よくある質問</a>
        </div>
        <square-button v-show="isNotContactPage" class="bar__contact-btn bar-wrap__right__contact" linkTo="/contact">
          お問い合わせ</square-button>
        <button v-show="isTop" class="bar-wrap__hamburger-menu-btn" v-on:click="isHamburgerOpen = !isHamburgerOpen">
          <span class="bar-wrap__hamburger-menu"></span>
        </button>
      </div>
    </nav>
  </div>
</template>

<script>
import SquareButton from "@/components/atoms/SquareButton.vue";
export default {
  components: { SquareButton },
  name: "TopBar",
  data: function () {
    return {
      isHamburgerOpen: false,
    };
  },
  methods: {
    clickSmoothScroll(id) {
      event.preventDefault();
      this.isHamburgerOpen = false;
      if (this.isTop) {
        this.$smoothScroll({
          scrollTo: document.querySelector(id),
          duration: 1300,
          easingFunction: (t) =>
            t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t,
        });
      } else {
        this.$router.push("/");
      }
    },
  },
  computed: {
    isTop: function () {
      return this.$route.path === "/";
    },
    isNotContactPage: function () {
      return this.$route.path !== "/contact";
    },
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: black;
}

.bar {
  position: fixed;
  width: 100vw;
  height: 60rem;
  background-color: white;
  box-shadow: 0rem 2rem 2rem #00000029;
  z-index: 1000;
}

.bar-wrap {
  height: 100%;
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    height: 100%;

    &__brand {
      display: block;
      height: 100%;
      color: black;
      text-decoration: none;
      @extend .fs--xxxl;
      font-weight: 700;
      line-height: 60rem;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    height: 100%;

    &__sections {
      display: flex;
      margin-right: 90rem;
      align-items: center;
      height: 100%;

      @include mq(lg) {
        margin-right: 10rem;
      }

      &--hamburger {
        @include mq(md) {
          display: none;
          opacity: 0;

          &--active {
            animation: bar-wrap__right__sections--hamburger--active 200ms;
            display: block;
            z-index: -1;
            background: #ffffff;
            position: absolute;
            top: 60rem;
            bottom: 0rem;
            left: 0rem;
            right: 0rem;
            width: 100vw;
            height: 100vh;
            opacity: 1;
          }
        }
      }

      &__section {
        height: 100%;
        padding: 0rem 30rem;
        @extend .fs--l;
        font-weight: 700;
        line-height: 60rem;
        color: #444455;

        @include mq(lg) {
          padding: 0rem 20rem;
        }

        &:hover {
          transition: color 300ms ease 0s;
          color: #4444558f;
        }

        &--hamburger-item {
          @include mq(md) {
            display: block;
            text-align: left;
            background: #ffffff;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            height: auto;

            &:first-child {
              box-shadow: inset 0rem 2rem 2rem #00000029;
            }
          }
        }
      }
    }

    &__contact {
      color: white;
      @extend .fs--r;
      font-weight: 500;
      background: #ff8546;
      width: 157rem;
      height: 36rem;

      &:hover {
        background: #f07c41;
      }

      @include mq(xs) {
        display: none;
      }
    }
  }

  &__hamburger-menu-btn {
    display: none;
    outline: none;

    @include mq(md) {
      margin-left: 30rem;
      height: 50rem;
      width: 50rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__hamburger-menu {
    content: "";
    display: block;
    height: 3rem;
    width: 25rem;
    border-radius: 3rem;
    background-color: #444455;
    position: relative;

    &:before,
    &:after {
      content: "";
      display: block;
      height: 3rem;
      width: 25rem;
      border-radius: 3rem;
      background-color: #444455;
      position: relative;
    }

    &:before {
      bottom: 8rem;
    }

    &:after {
      top: 5rem;
    }
  }
}

@keyframes bar-wrap__right__sections--hamburger--active {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

a {
  text-decoration: none !important;
  /* Contactページのbootstrapのスタイルを消すため */
}
</style>
