<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
      intersection: {
        threshold: 0.05,
      },
      throttle: throttle,
    }"
    v-bind:class="{ active: isVisible }"
    class="fade"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FadeIn",
  props: {
    throttle: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      isVisible: false,
    };
  },
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = isVisible;
    },
  },
};
</script>

<style scoped>
.fade {
  opacity: 0;
  transform: translate(0, 50rem);
  transition: all 1s;
}
.fade.active {
  opacity: 1;
  transform: translate(0, 0);
}
</style>