<template>
  <detail :topTitle="topTitle" :title="title">
    <h2 class="detail-content__sections__section__title">検知結果の取得</h2>
    <p class="detail-content__sections__section__text">
      Stena Gameでは検知結果を取得するためのAPIを公開しています。
      社内分析での利用や、ユーザの対処自動化システムへの繋ぎ込みなど、検知結果をシームレスに活用することが出来ます。
      API KeyはStena GameのWeb UIから取得しすぐにお使いいただけます。
    </p>
    <img class="detail-content__sections__section__image" src="@/assets/api_ui.png" decoding="async" />
  </detail>
</template>

<script>
import Detail from "@/pages/Detail.vue";
export default {
  components: { Detail },
  data: function () {
    return {
      topTitle: "提供API",
      title: "提供API",
    };
  },
};
</script>

<style lang="scss" scoped>
.detail-content__sections__section__image {
  margin-bottom: 60rem;
}
</style>