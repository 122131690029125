<template>
  <div class="top-image-card-with-number">
    <div class="top-image-card-with-number__number">
      {{ number | toStringPadZero }}
    </div>
    <top-image-card class="top-image-card-with-number__main" :image="image"
      ><template v-slot:title><slot name="title"></slot></template><slot></slot
    ></top-image-card>
  </div>
</template>

<script>
import TopImageCard from "@/components/molecules/TopImageCard.vue";
export default {
  components: { TopImageCard },
  props: {
    number: { type: Number, default: 0 },
    image: { type: String, require: true },
  },
  filters: {
    toStringPadZero: function (num) {
      if (typeof num === "number") {
        return num.toString().padStart(2, "0");
      } else if (typeof num === "string") {
        return num.padStart(2, "0");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.top-image-card-with-number {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include mq(md) {
    flex-direction: row;
  }
  &__number {
    color: #444455;
    @extend .fs--xxxxl;
    font-weight: 700;
    letter-spacing: 2.3rem;
    margin-bottom: 8rem;
    @include mq(md) {
      margin: auto;
      padding: 20rem;
    }
    @include mq(sm) {
      display: none;
    }
  }
  &__main {
    height: 100%;
    background: #f8f8f8;
  }
}
</style>