<template>
  <article>
    <!-- ファーストビュー -->
    <Top id="top" />
    <!-- About -->
    <About id="about" />
    <!-- Stenaの特徴 -->
    <Feature id="feature" />
    <!--導入実績 -->
    <Customer id="customer" />
    <!-- 導入ステップ -->
    <Step id="step" />
    <!-- FQA -->
    <FQA id="faq" />
  </article>
</template>

<script>
import Top from "@/components/contents/Top.vue";
import About from "@/components/contents/About.vue";
import Feature from "@/components/contents/Feature.vue";
import Customer from "@/components/contents/Customer.vue";
import Step from "@/components/contents/Step.vue";
import FQA from "@/components/contents/FQA.vue";

export default {
  name: "Main",
  components: {
    Top,
    About,
    Feature,
    Customer,
    Step,
    FQA,
  },
};
</script>

<style scoped>
</style>
