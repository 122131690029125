<template>
  <detail :topTitle="topTitle" :title="title">
    <div class="detail-content__sections__section">
      <h2 class="detail-content__sections__section__title">
        任意のクラウドサーバと連携可能
      </h2>
      <p class="detail-content__sections__section__text">
        ログサーバからStena Gameへゲームログの送信設定を行っていただくことで、それ以降自動でログを受信し検知を行うことができます。Stena
        Gameのログサーバは、BigQuery、AWS、オンプレミスに対応しており、ゲームの運用形態に合わせてお選びいただけます。
      </p>
      <img class="detail-content__sections__section__image" src="@/assets/log_bq.svg" decoding="async" />
    </div>
    <div class="detail-content__sections__section">
      <h2 class="detail-content__sections__section__title">ログの形式例</h2>
      <div class="detail-content__sections__section__text">
        <p>
          ログに含まれる情報は最低限「ユーザID」「リクエストURI」「リクエスト時間」のみでBotなどのプレイ傾向が通常のプレイから逸脱した不正を検知することができますが、検知項目によってはリクエストに含まれるパラメータが必要となったり、パラメータを加味した方が精度が向上する場合があります。
          また、検知モデル作成の際には過去に他のユーザから不正報告されているなど、今までに不正と判断されたユーザのリストがある場合は「ラベル」として使用することで検知精度を大きく向上させることができます。
        </p>
        <p>
          データの形式や量と精度のトレードオフ関係については検知モデル作成の際にも実際の検知精度を交えてご案内いたします。
        </p>
      </div>
    </div>
  </detail>
</template>

<script>
import Detail from "@/pages/Detail.vue";
export default {
  components: { Detail },
  data: function () {
    return {
      topTitle: "ログの入力と形式",
      title: "ログの送信設定のみで不正検知をはじめる",
    };
  },
};
</script>

<style lang="scss" scoped>
p+p {
  margin-top: $regular-font-size;
}
</style>