<template>
  <span class="plus-icon" :class="{ 'plus-icon--active': active }"></span>
</template>

<script>
export default {
  props: {
    active: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.plus-icon {
  display: block;
  position: relative;
  &:before,
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background: #707070;
    position: absolute;
    right: 0;
    top: calc(50% - 2px); //真ん中に持ってくるため(height/2)分上に移動
  }
  &:after {
    transform: rotate(90deg);
    transition: 0.5s;
  }
  &--active::after {
    transform: rotate(0);
  }
}
</style>