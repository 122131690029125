var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    once: true,
    intersection: {
      threshold: 0.05,
    },
    throttle: _vm.throttle,
  }),expression:"{\n    callback: visibilityChanged,\n    once: true,\n    intersection: {\n      threshold: 0.05,\n    },\n    throttle: throttle,\n  }"}],staticClass:"fade",class:{ active: _vm.isVisible }},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }