<template>
  <div class="frame"><slot></slot></div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.frame {
  width: 1174rem;
  margin: auto;
  padding-top: 60rem;
  padding-bottom: 120rem;
  @include mq(lg) {
    width: 960rem;
  }
  @include mq(md) {
    width: 720rem;
  }
  @include mq(sm) {
    width: 540rem;
  }
  @include mq(xs) {
    width: 94%;
  }
}
</style>