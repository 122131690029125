<template>
  <span class="list-item">
    <slot></slot>
  </span>
</template>

<script>
export default {};
</script>

<style scoped>
.list-item {
  display: block;
  text-align: center;
  background: #4e5666;
  border-radius: 8rem;
  width: 234rem;
  height: 49rem;
  line-height: 49rem;
  color: white;
  font-weight: 500;
}
</style>