<template>
  <div id="app">
    <TopBar />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import TopBar from "@/components/contents/TopBar.vue";
import Footer from "@/components/contents/Footer.vue";
import "ress";

export default {
  name: "app",
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: noto-sans-cjk-jp, "Noto Sans", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #212f3e;
  font-size: $regular-font-size;
}
html.wf-loading {
  opacity: 0 !important;
}
html.wf-active,
html.wf-inactive {
  transition: opacity 1s ease-in-out 500ms;
  opacity: 1;
}
html {
  font-size: 0.875px; // 1rem を 1pxに → xdの見た目とmacbookの表示揃うように * 1680/1920
  opacity: 0;
}
</style>
