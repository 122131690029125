<template>
  <div class="triangle-arrow-right"></div>
</template>

<script>
export default {};
</script>

<style>
.triangle-arrow-right {
  width: 0;
  height: 0;
  border-top: 15rem solid transparent;
  border-bottom: 15rem solid transparent;
  border-left: 15rem solid #4b4b4b;
}
</style>