<template>
  <ul class="check-list">
    <li v-for="(value, i) in list" :key="i">{{ value }}</li>
  </ul>
</template>

<script>
export default {
  props: {
    list: { type: Array, default: () => [] },
  },
};
</script>

<style lang="scss" scoped>
.check-list {
  list-style: none;
  > li {
    background-image: url("../../assets/checkmark-24px.svg");
    background-repeat: no-repeat;
    background-position: left 1rem;
    @extend .fs--l;
    padding-left: 30rem;
    font-weight: 500;
    margin-bottom: 18rem;
  }
}
</style>