<template>
  <div class="text-with-image" :class="positionClass">
    <div class="text-with-image__main" :class="mainPositionClass">
      <div class="text-with-image__main__title">
        <h3><slot name="title"></slot></h3>
      </div>
      <div
        class="text-with-image__main__text text-with-image__main__text--left"
      >
        <p><slot></slot></p>
      </div>
    </div>
    <div
      class="text-with-image__image"
      :class="imagePositionClass"
      :style="imageStyles"
    >
      <props-image :src="image" />
    </div>
  </div>
</template>

<script>
import PropsImage from "@/components/atoms/PropsImage.vue";
export default {
  components: { PropsImage },
  props: {
    imagePosition: { type: String, default: "right" },
    image: { type: String, require: true },
    imageWidth: { type: String, default: "40%" },
  },
  computed: {
    positionClass() {
      return "text-with-image--" + this.imagePosition;
    },
    mainPositionClass() {
      return (
        "text-with-image__main--" +
        (this.imagePosition === "right" ? "left" : "right")
      );
    },
    imagePositionClass() {
      return "text-with-image__image--" + this.imagePosition;
    },
    imageStyles() {
      return {
        "--image-width": this.imageWidth,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.text-with-image {
  display: flex;
  align-items: center;
  @include mq(md) {
    flex-direction: column;
  }
  &--left {
    flex-direction: row-reverse;
    @include mq(md) {
      flex-direction: column;
    }
  }
  &__main {
    flex: 1 1 auto;
    width: 60%;
    @include mq(md) {
      width: 100%;
    }
    &--right {
      margin-left: 60rem;
      @include mq(md) {
        margin-left: 0rem;
      }
    }
    &--left {
      margin-right: 60rem;
      @include mq(md) {
        margin-right: 0rem;
      }
    }
    &__title {
      @extend .fs--xxxl;
      font-weight: 700;
      color: #000000;
      letter-spacing: 1.7rem;
      line-height: 1.411764706;
      text-align: left;
      margin-bottom: 30rem;
      white-space: pre-wrap;
      word-wrap: break-word;
      @include mq(lg) {
        font-size: 28rem;
      }
      @include mq(md) {
        margin-bottom: 20rem;
      }
    }
    &__text {
      @extend .fs--l;
      color: #444455;
      letter-spacing: 0.9rem;
      text-align: left;
      white-space: pre-wrap;
      word-wrap: break-word;
      order: 2;
      line-height: 2.111111111;
      @include mq(md) {
        margin-bottom: 60rem;
      }
    }
  }
  &__image {
    flex: 0 0 var(--image-width);
    @include mq(lg) {
      flex: 0 0 calc(var(--image-width) * 0.7);
    }
    @include mq(md) {
      flex: 0 0 auto;
    }
    > img {
      max-width: 100%;
      height: auto;
      @include mq(md) {
        width: var(--image-width);
      }
    }
  }
}
</style>