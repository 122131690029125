<template>
  <div class="detail">
    <div class="detail__container">
      <div class="detail__top-header">
        <h3 class="detail__top-header__guide">特徴ガイド</h3>
        <h2 class="detail__top-header__title">Stena Gameを使った不正検知の特徴</h2>
      </div>
      <side-bar class="detail__side-bar" :sections="sidebarSections" :title="sidebarTitle"></side-bar>
      <detail-content class="detail__content"><template v-slot:topTitle>{{ topTitle }}</template><template
          v-slot:title>{{ title }}</template>
        <slot></slot>
      </detail-content>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/atoms/SideBar.vue";
import DetailContent from "@/components/contents/DetailContent.vue";
import paths from "@/router/paths.js";

export default {
  components: { SideBar, DetailContent },
  props: {
    title: String,
    topTitle: String,
  },
  data: function () {
    return {
      sidebarTitle: "特徴ラインナップ",
      sidebarSections: [
        {
          name: "検知機能",
          items: [
            { name: "事後対策の効果", link: paths.featureEffectPath },
            { name: "検知モデルの種類", link: paths.featureModelPath },
            { name: "モデルアップデート", link: paths.featureUpdatePath },
          ],
        },
        {
          name: "結果管理",
          items: [
            { name: "不正ユーザの集計", link: paths.managerAggregatePath },
            { name: "不正傾向の分析", link: paths.managerAnalyzePath },
          ],
        },
        {
          name: "運営方法",
          items: [
            { name: "ログの入力と形式", link: paths.operationLogPath },
            { name: "提供API", link: paths.operationApiPath },
            { name: "誤検知対策", link: paths.operationSafePath },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.detail {
  background: #f8f8f8;
  min-height: calc(100vh - 90rem);

  &__container {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 270rem auto;
    justify-content: center;
    padding-top: 60rem;
    padding-bottom: 120rem;
    margin-left: -270rem;

    @include mq(lg) {
      margin-left: 0;
      grid-template-columns: auto;
    }
  }

  &__top-header {
    padding-top: 60rem;
    padding-bottom: 60rem;
    grid-column: 2;
    grid-row: 1;

    &__guide {
      @extend .fs--r;
      font-weight: 700;
      letter-spacing: 0.7rem;
      color: #444455;
    }

    &__title {
      @extend .fs--xxl;
      font-weight: 700;
      letter-spacing: 1.2rem;
      color: #444455;
      margin-top: 15rem;
    }
  }

  &__side-bar {
    grid-column: 1;
    grid-row: 2;
    align-self: flex-start;
    margin-right: 30rem;

    @include mq(lg) {
      margin-right: 0rem;
      margin-top: 30rem;
      grid-column: 2;
      grid-row: 3;
    }
  }

  &__content {
    grid-column: 2;
    grid-row: 2;
    width: 832rem;

    @include mq(lg) {
      margin: auto;
    }

    @include mq(sm) {
      width: 640rem;
    }

    @include mq(xs) {
      width: 96%;
    }
  }
}
</style>