<template>
  <detail :topTitle="topTitle" :title="title">
    <div class="detail-content__sections__section">
      <h2 class="detail-content__sections__section__title">事後対策とは</h2>
      <p class="detail-content__sections__section__text">
        事後対策とは、ユーザがゲームプレイ中にサーバにアクセスした際のログから不正な行動を検知する対策で、サーバサイド検知とも呼ばれます。<br />
        一方、不正ができないように事前にアプリやユーザの端末にブロックする仕組みを備えておく対策を事前対策と呼びます。
      </p>
      <img v-if="isWindowMedium" class="detail-content__sections__section__image"
        src="@/assets/effect_post_measure_vertical.png" decoding="async" />
      <img v-else class="detail-content__sections__section__image" src="@/assets/effect_post_measure.svg"
        decoding="async" />
      <div class="post-measure-effect__text">多くのオンラインゲームでは</div>
      <div class="post-measure-effect__text">
        <span class="post-measure-effect__text--strong">「限られた不正を事前に防ぐ」 </span>より<span
          class="post-measure-effect__text--strong post-measure-effect__text--red">「後からでも不正を確実に対処する」</span>ことが重要
      </div>
    </div>
    <div class="detail-content__sections__section">
      <h2 class="detail-content__sections__section__title">
        事前対策と事後対策の比較
      </h2>
      <p class="detail-content__sections__section__text">
        「事前対策」は不正手法への対策を行うアプローチです。
        この段階で全ての不正を防ぎきることが望ましいですが、実際には新たな不正手法が次々と出現しています。
        そのため、1種類の不正を防ぐために何種類もの不正手法の対策を実装する必要がある上、それでも全てを防ぎきることはできません。
        実装方法によっては、ユーザ端末やアプリサーバへの負荷と不対策強度とのトレードオフとなる場合もあります。<br /><br />
        一方で、あらゆる対策をすり抜ける巧妙な手法でも、不正により得られる結果は隠せません。
        不正によりゲームプレイに大きな影響を与えるのでなければ、そもそも不正を行う意味がないからです。
        「事後対策」は不正が行われた結果に着目するアプローチで、本来ならば手法に関係なく不正ユーザを突き止めることができます。
        しかし、全てのユーザの行動を監視し分析を行うには莫大な人的コストが必要という大きなデメリットがあります。
      </p>
    </div>
    <div class="detail-content__sections__section">
      <h2 class="detail-content__sections__section__title">Stena Gameでは</h2>
      <p class="detail-content__sections__section__text">
        ユーザの行動すべてを自動で分析し、「事後対策」の課題である人的コストを大きく削減します。<br />
        必要な情報はユーザの行動ログだけであるため、ユーザ端末やアプリサーバへの負荷のない不正対策を実現できます。
      </p>
    </div>
  </detail>
</template>

<script>
import Detail from "@/pages/Detail.vue";
export default {
  components: { Detail },
  data: function () {
    return {
      topTitle: "事後対策の効果",
      title: "事後対策で不正を確実に対処",
      width: window.innerWidth,
    };
  },
  computed: {
    isWindowMedium: function () {
      return this.width < 768;
    },
  },
  methods: {
    handleResize: function () {
      this.width = window.innerWidth;
    },
  },
  mounted: function () {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.post-measure-effect__text {
  text-align: center;
  @extend .fs--s;
  letter-spacing: 0.6rem;
  margin-top: 30rem;
  line-height: 2;

  &:last-child {
    margin-top: 0rem;
  }

  &--strong {
    @extend .fs--r;
    font-weight: 700;
  }

  &--red {
    color: #ff5e83;
  }
}

.detail-content__sections__section__image {
  width: 100%;

  @include mq(lg) {
    width: 100%;
  }

  @include mq(md) {
    width: 100%;
  }

  @include mq(sm) {
    width: 80%;
  }

  @include mq(xs) {
    width: 90%;
  }
}
</style>