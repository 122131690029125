<template>
  <section class="feature">
    <Frame>
      <section-title>FEATURE<template v-slot:subtitle>Stena Gameの特徴</template></section-title>
      <div class="feature__description">
        <ul class="feature__description__features">
          <li class="feature__description__features__item">
            <span class="feature__description--strong">①強力な検知能力</span>
          </li>
          <li class="feature__description__features__item">
            <span class="feature__description--strong">②すぐに確認できる管理画面</span>
          </li>
          <li class="feature__description__features__item">
            <span class="feature__description--strong">③負担の少ない運用形態</span>
          </li>
        </ul>
        を追求し、不正検知を誰でも簡単に行える仕組みを揃えています。
      </div>
      <square-button class="feature__description__button" :linkTo="linkTo">Stena Gameの特長を詳しく見る</square-button>

      <div class="feature__features">
        <FadeIn class="feature__features__item">
          <card-with-number :number="1" image="feature_behavioral_analytics.png" :linkTo="featureEffectPath"><template
              v-slot:title>2000万以上のユーザの行動をすべて解析。<span class="br--xs" />
              人手の監視コストを大幅に削減。</template>
            <template v-slot:content>
              <p>
                世界最高精度の異常検知技術を最適な形でチューニングしたAIが膨大なユーザの行動ログをすべて解析します。
              </p>
              <p>
                誤検知率0.0001%以下の高い検知能力を保持しており、セキュリティエキスパートによる入念な調査に匹敵する水準の分析をすべてのユーザに対して行うことができます。
              </p>
            </template>
            <template v-slot:button>検知能力を詳しく見る</template>
          </card-with-number>
        </FadeIn>
        <FadeIn class="feature__features__item">
          <card-with-number :number="2" image="feature_manager_ui.png" :linkTo="managerAggregatePath"><template
              v-slot:title>全体の不正傾向から一人ひとりの詳細まで<span class="br--xs" /> Web
              UIで自在に確認。</template>
            <template v-slot:content>
              <p>
                日々の検知結果は自動で集計・管理され、アプリ全体での不正傾向はグラフから一目で確認できます。
              </p>
              <p>
                全体の不正傾向からユーザの一覧表示へ、さらに一人ひとりのユーザの不正状況へと、統計から詳細までクリック一つで自在に切り替えられるため、誰でもかんたんに分析ができます。
              </p>
            </template>

            <template v-slot:button>結果管理を詳しく見る</template>
          </card-with-number>
        </FadeIn>
        <FadeIn class="feature__features__item">
          <card-with-number :number="3" image="feature_management_flow.svg" :linkTo="operationLogPath"><template
              v-slot:title>現在の運用システム構成そのままで<span class="br--xs" />不正検知フローを実現。</template>
            <template v-slot:content>
              <p>
                Stena
                Gameを使うためにアプリサーバへの新たな機能実装は必要なく、ログを送信するだけで検知を行うことができます。アプリ本体へのソフトウェアインストールも不要でユーザ端末の負担が増えることもありません。<br />
                Stena Gameはクラウドで動作し<span
                  class="feature__features__item__text-small">※1</span>、システム導入のために新たなサーバを用意することなく不正検知フローを実現できます。
              </p>
              <p class="feature__features__item__text-small feature__features__item__text-small--align-right">
                ※1 ご希望によりオンプレミスにStena Gameを動作させることも可能です。
              </p>
            </template>
            <template v-slot:button>運用方法を詳しく見る</template>
          </card-with-number>
        </FadeIn>
      </div>
    </Frame>
  </section>
</template>

<script>
import SectionTitle from "@/components/atoms/SectionTitle.vue";
import FadeIn from "@/components/contents/FadeInAnimation.vue";
import Frame from "@/components/contents/Frame.vue";
import CardWithNumber from "@/components/molecules/CardWithNumber.vue";
import paths from "@/router/paths.js";
import SquareButton from "../atoms/SquareButton.vue";

export default {
  components: {
    FadeIn,
    SectionTitle,
    Frame,
    SquareButton,
    CardWithNumber,
  },
  data: function () {
    return {
      linkTo: paths.featureEffectPath,
      featureEffectPath: paths.featureEffectPath,
      managerAggregatePath: paths.managerAggregatePath,
      operationLogPath: paths.operationLogPath,
    };
  },
};
</script>

<style scoped lang="scss">
.br--xs::after {
  width: 0px;
  content: "\A";
  white-space: pre;

  @include mq(xs) {
    content: "";
  }
}

.feature {
  background: #fff;

  &__description {
    @extend .fs--xxl;
    color: #444455;
    text-align: center;
    letter-spacing: 1.2rem;
    margin-top: 98rem;
    line-height: 2.181818182;

    &__features {
      list-style: none;

      @include mq(md) {
        margin-bottom: 10rem;
      }

      &__item {
        display: inline;

        @include mq(md) {
          display: block;
        }
      }

      &__item+&__item {
        margin-left: 16rem;

        @include mq(md) {
          margin-left: 0;
          margin-top: -10rem;
        }
      }
    }

    &--strong {
      font-weight: 700;
    }

    &__button {
      @extend .fs--14;
      display: inline-block;
      margin-top: 86rem;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__features {
    margin-top: 94rem;

    &__item {
      margin-bottom: 94rem;

      &:last-child {
        margin-bottom: 0rem;
      }

      &__text-small {
        @extend .fs--xs;

        &--align-right {
          text-align: right;
        }
      }
    }
  }
}
</style>
