<template>
  <div class="section-title">
    <h2 class="section-title__title"><slot></slot></h2>
    <div class="section-title__subtitle"><slot name="subtitle"></slot></div>
    <div class="section-title__line"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.section-title {
  display: flex;
  align-items: baseline;
  &__title {
    font-weight: 500;
    color: #444455;
    @extend .fs--xxxxxxl;
  }
  &__subtitle {
    color: #444455;
    @extend .fs--xxl;
    font-weight: 500;
    margin-left: 64rem;
    @include mq(xs) {
      display: none;
    }
  }
  &__line {
    flex: 1 1 auto;
    align-self: flex-end;
    background: #cbcbcb;
    height: 2rem;
    margin-bottom: 25rem;
    margin-left: 71rem;
    @include mq(xs) {
      margin-left: 20rem;
    }
  }
}
</style>