<template>
  <detail :topTitle="topTitle" :title="title">
    <h2 class="detail-content__sections__section__title">
      入力データのフィルタリング
    </h2>
    <p class="detail-content__sections__section__text">
      ゲームサーバへのアクセスログから不正を検知する手法では、ユーザからの予期せぬリクエストが不正検知結果にも影響を与えてしまうケースも考えられます。
      リクエスト先のURIを誤って設定してしまうなど、アプリの不具合が原因で意図せぬ形式のログが記録されてしまう場合もあれば、悪意のあるユーザがサーバのロジックエラーを狙って存在しないURIへのアクセスが試みられる場合も想定されます。
      Stena Gameではそのような予期せぬフォーマットのログをフィルタリングする機能を搭載しているためアプリの不具合や攻撃の際にも影響は最小限のまま安心してお使いいただけます。
    </p>
    <!-- TODO: 画像 -->
    <h2 class="detail-content__sections__section__title">検知数上限設定</h2>
    <p class="detail-content__sections__section__text">
      一日の検知数上限に達した場合にアラートを鳴らしたり、それ以上の検知を一時的に停止させることで万が一の場合でも被害を最小限に抑えることができます。
      当社サポートにより不具合が生じた日の検知結果はアーカイブするなど、後日に不正履歴を確認する際に不正と表示され続けないよう対応いたします。
    </p>
  </detail>
</template>

<script>
import Detail from "@/pages/Detail.vue";
export default {
  components: { Detail },
  data: function () {
    return {
      topTitle: "誤検知対策",
      title: "予期せぬ障害時でも被害を最小限に",
    };
  },
};
</script>

<style lang="scss" scoped>
</style>