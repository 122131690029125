<template>
  <div class="top-image-card">
    <div class="top-image-card__title"><slot name="title"></slot></div>
    <div class="top-image-card__main">
      <props-image class="top-image-card__main__image" :src="image" />
      <div class="top-image-card__main__content"><slot></slot></div>
    </div>
  </div>
</template>

<script>
import PropsImage from "@/components/atoms/PropsImage.vue";
export default {
  components: { PropsImage },
  props: {
    image: { type: String, require: true },
  },
};
</script>

<style scoped lang="scss">
.top-image-card {
  box-shadow: 0rem 3rem 8rem #00000014;
  @include mq(md) {
    display: flex;
  }
  &__title {
    background: #5a5a5a;
    color: white;
    text-align: center;
    height: 47rem;
    line-height: 47rem;
    font-weight: 500;
    @extend .fs--l;
    @include mq(md) {
      flex: 0 0 30%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @include mq(sm) {
      flex: 0 0 160rem;
    }
  }
  &__main {
    background: #f8f8f8;
    color: #444455;
    padding: 0rem 18rem 30rem 18rem;
    @include mq(lg) {
      padding: 20rem 10rem 30rem 10rem;
    }
    @include mq(md) {
      height: auto;
      display: flex;
    }
    @include mq(sm) {
      height: 156rem;
      padding: 20rem;
    }
    @include mq(xs) {
      height: auto;
    }
    &__image {
      width: auto;
      height: 50rem;
      margin-top: 30rem;
      margin-left: auto;
      margin-right: auto;
      @include mq(lg) {
        margin-top: 20rem;
        max-height: 100%;
        margin: auto;
      }
      @include mq(sm) {
        display: none;
      }
    }
    &__content {
      text-align: left;
      @extend .fs--r;
      letter-spacing: 0.7rem;
      line-height: 1.714285714;
      margin-top: 30rem;
      margin-bottom: auto;
      @include mq(md) {
        flex: 0 0 80%;
        margin-top: auto;
        padding-left: 20rem;
      }
      @include mq(sm) {
        flex: 0 0 100%;
        padding-left: 0rem;
      }
    }
  }
}
</style>