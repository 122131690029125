<template>
  <section>
    <Frame class="top__frame">
      <div class="top-contents">
        <div class="top-contents__product-name">
          不正検知プラットフォーム Stena Game
        </div>
        <h1 class="top-contents__title">
          行動分析AIでゲームの<br />不正ユーザを自動検知
        </h1>
        <check-list class="top-contents__benefits" :list="benefits"></check-list>
        <square-button background="#FF8546" class="top-contents__button" linkTo="/contact">お問い合わせ</square-button>
      </div>
      <div class="top-img">
        <img src="@/assets/top_ui.png" decoding="async" />
      </div>
    </Frame>
  </section>
</template>

<script>
/* eslint-disable */
import CheckList from "@/components/atoms/CheckList.vue";
import SquareButton from "@/components/atoms/SquareButton.vue";
import Frame from "@/components/contents/Frame.vue";
export default {
  components: {
    Frame,
    SquareButton,
    CheckList,
  },
  data: function () {
    return {
      benefits: [
        "不正発見プロセスを自動化で運用コストを削減",
        "膨大なログを毎日欠かさず自動監視",
        "ゲームタイトルごとに専用のモデルを作成",
      ],
    };
  },
  methods: {
    load() {
      console.log("he");
      this.didLoad = true;
    },
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}

.top {
  &__frame {
    padding-top: 60rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -120rem;

    @include mq(md) {
      margin-bottom: 0rem;
    }

    @include mq(md) {
      flex-direction: column;
    }
  }
}

.top-contents {
  align-self: flex-start;
  text-align: left;
  margin-top: 120rem;
  margin-bottom: 120rem;
  flex: 0 0 auto;

  @include mq(lg) {
    margin-top: 110rem;
  }

  @include mq(md) {
    flex: 0 0 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60rem;
    margin-top: 60rem;
  }

  &__product-name {
    @extend .fs--xxl;
    font-weight: bold;
    letter-spacing: 1.2rem;

    @include mq(lg) {
      font-size: 22rem;
    }

    @include mq(md) {
      font-size: 20rem;
    }

    @include mq(xs) {
      font-size: 16rem;
    }
  }

  &__title {
    @extend .fs--xxxxxl;
    font-weight: bold;
    letter-spacing: 2.7rem;
    color: #2b2b2e;
    margin-top: 90rem;
    margin-bottom: 30rem;

    @include mq(lg) {
      font-size: 45rem;
      margin-top: 30rem;
    }

    @include mq(md) {
      font-size: 40rem;
    }

    @include mq(xs) {
      font-size: 30rem;
    }
  }

  &__benefits {
    font-weight: 500;
    margin-bottom: 90rem;
    letter-spacing: 0.9rem;

    @include mq(lg) {
      margin-bottom: 30rem;
    }
  }

  &__button {
    @extend .fs--l;
  }
}

.top-img {
  width: auto;
  margin-top: 60rem;
  margin-right: -100rem;

  @include mq(lg) {
    margin-top: 0rem;
    margin-right: -80rem;
  }

  @include mq(md) {
    width: 100%;
    margin-right: 0rem;
    margin-left: 0rem;
  }

  >img {
    margin: auto;
    width: 100%;
  }
}
</style>