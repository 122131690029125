<template>
  <detail :topTitle="topTitle" :title="title">
    <div class="detail-content__sections__section">
      <h2 class="detail-content__sections__section__title">
        教師ありモデル：検知したい不正を狙い打ちで確実に検知
      </h2>
      <p class="detail-content__sections__section__text">
        過去に不正ユーザを処理した記録がある場合は、その行動パターンを事前に学習することで似た行動をするユーザを検知することができます。
        ユーザの行動ログに「正常」か「不正」かの情報（ラベルと呼びます）を付与し、不正ユーザの行動と正常ユーザの行動を明確に区別して学習を行います。
      </p>
      <img
        class="detail-content__sections__section__image"
        src="@/assets/model_supervised.svg"
        decoding="async"
      />
      <div class="model-kind__model-sample">
        <h3 class="model-kind__model-sample__title">検知モデル例</h3>
        <div class="model-kind__model-sample__samples">
          <tag
            class="model-kind__model-sample__samples__item"
            v-for="(item, i) in modelSamples"
            :key="i"
            >{{ item }}</tag
          >
        </div>
      </div>
    </div>
    <div class="detail-content__sections__section">
      <h2 class="detail-content__sections__section__title">
        教師なしモデル：大多数と比べて異常な行動をするユーザを発見
      </h2>
      <p class="detail-content__sections__section__text">
        不正の種類を絞らずに、他のユーザとは漠然と行動パターンが異なるユーザを検知します。
        過去の不正傾向に縛らず、新しい不正やユーザの予期せぬ行動を発見することができます。
      </p>
      <img
        class="detail-content__sections__section__image"
        src="@/assets/model_unsupervised.svg"
        decoding="async"
      />
      <p class="detail-content__sections__section__text">
        教師なし学習で作成したモデルでは、ゲームバランスを大きく崩す恐れのある攻略パターンをとるユーザなど、大多数のユーザと異なる行動をとるユーザを発見することができます。
      </p>
    </div>
  </detail>
</template>

<script>
import Detail from "@/pages/Detail.vue";
import Tag from "@/components/atoms/Tag.vue";

export default {
  components: { Detail, Tag },
  data: function () {
    return {
      topTitle: "検知モデルの種類",
      title: "「教師ありモデル」と「教師なしモデル」であらゆる不正を検知",
      modelSamples: [
        "無限レベルアップ",
        "アイテム増殖",
        "オートプレイ",
        "RMT用アカウント",
        "ステータス操作",
        "ダメージ補正",
        "位置偽装",
        "煽りプレイ",
        "オートエイム",
        "ステージ開放",
        "無限ガチャ",
        "ランキング操作",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.model-kind__model-sample {
  border: 2px dashed #707070;
  border-radius: 36rem;
  text-align: left;
  margin-top: 40rem;
  &__title {
    display: inline;
    @extend .fs--s;
    text-align: left;
    letter-spacing: 0.6rem;
    color: #444444;
    background: white;
    position: relative;
    left: 72rem;
    top: -15rem;
    padding: 8rem;
  }
  &__samples {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 4rem;
    margin-left: 18rem;
    margin-right: 18rem;
    @include mq(sm) {
      justify-content: space-around;
    }
    @include mq(xs) {
      justify-content: space-around;
    }
    &__item {
      @extend .fs--xs;
      width: 132rem;
      height: 29rem;
      line-height: 29rem;
      margin-bottom: 16rem;
    }
  }
}
</style>