<template>
  <div class="round-box"><slot></slot></div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.round-box {
  background: #ffffff;
  border: 3rem solid #cbcbcb;
  border-radius: 12rem;
  @extend .fs--l;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #444455;
}
</style>