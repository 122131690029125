<template>
  <section class="fqa">
    <Frame class="fqa__container">
      <section-title class="fqa__title">FAQ<template v-slot:subtitle>よくある質問</template></section-title>
      <div class="fqa__questions">
        <fqa-item class="fqa__questions__question"><template v-slot:question>価格体系について教えてください。</template><template
            v-slot:answer>
            <p>
              価格はお客様のプロジェクト形態に合わせて、定額プラン、検知ログ量に基づく従量課金プラン、DAUに基づく従量課金プランをご用意しております。資料をお送りいたしますので<router-link
                class="fqa__questions__question__answer-link" to="/contact">
                こちら </router-link>からお問い合わせください。
            </p>
          </template></fqa-item>
        <fqa-item class="fqa__questions__question"><template
            v-slot:question>継続運用する前に自社サービスにどれくらい不正ユーザがいるか知りたいです。</template><template v-slot:answer>
            <p>
              ゲームログのサンプルをご提供いただき、現在の不正ユーザの規模感を調査するお試しサービスも実施しております。<router-link
                class="fqa__questions__question__answer-link" to="/contact">
                こちら </router-link>からのお問い合わせにてお試しをご希望する旨をお知らせください。
            </p>
          </template></fqa-item>
        <fqa-item class="fqa__questions__question"><template v-slot:question>検知に必要なログの形式を教えてください。</template><template
            v-slot:answer>
            <p>
              ログに含まれる情報は最低限「ユーザID」「リクエストURI」「リクエスト時間」のみでBotなどのプレイ傾向が通常のプレイから逸脱した不正を検知することができます。詳細は<router-link
                class="fqa__questions__question__answer-link" to="/operation/log">
                特徴ガイド </router-link>をご覧ください。
            </p>
          </template></fqa-item>
        <fqa-item class="fqa__questions__question"><template
            v-slot:question>ゲーム以外で不正検知をしたいサービスがあるのですが。</template><template v-slot:answer>
            <p>
              弊社はゲーム以外でも不正検知サービス提供実績が複数あり、Stena Gameをお客さまのサービス用に調整することも可能です。
              ご質問等・ご相談も<router-link class="fqa__questions__question__answer-link" to="/contact">
                こちら </router-link>からお気軽にお問い合わせください。
            </p>
          </template></fqa-item>
      </div>
    </Frame>
  </section>
</template>

<script>
import FqaItem from "@/components/atoms/FqaItem.vue";
import SectionTitle from "@/components/atoms/SectionTitle.vue";
import Frame from "@/components/contents/Frame.vue";

export default {
  components: {
    SectionTitle,
    Frame,
    FqaItem,
  },
};
</script>

<style scoped lang="scss">
.fqa {
  background: #f4f5f6;

  &__container {}

  &__title {
    margin-bottom: 60rem;
  }

  &__questions {
    &__question {
      margin-bottom: 20rem;

      &:last-child {
        margin-bottom: 0rem;
      }

      &__answer-link {
        color: #33a5cb;
        text-decoration: none;
      }
    }
  }
}
</style>