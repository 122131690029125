<template>
  <div class="form-item row my-3">
    <label
      class="col-sm-10 col-md-6 col-lg-3 col-2 contact-label"
      :class="isTextArea ? 'align-self-start' : 'align-self-center'"
      >{{ label }}</label
    >
    <div class="col-sm-1 col-2 align-self-center">
      <div class="mx-auto py-1" :class="{ 'require-form': required }">
        {{ requiredLabel }}
      </div>
    </div>
    <textarea
      v-if="isTextArea"
      class="col-md-12 col-8 col-lg-7 form-item__input"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <input
      v-else
      class="col-md-12 col-8 col-lg-7 m-auto form-item__input"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    name() {
      return this.label;
    },
    value() {
      return this.value;
    },
  },
  props: {
    label: String,
    required: Boolean,
    value: String,
    placeholder: String,
    type: { type: String, default: "text" },
  },
  computed: {
    requiredLabel() {
      return this.required ? "必須" : "";
    },
    isTextArea() {
      return this.type === "textarea";
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  padding: 10rem 15rem 10rem 15rem;
}
textarea {
  padding: 10rem 15rem 10rem 15rem;
}
.my-3 {
  margin-top: 15rem;
  margin-bottom: 15rem;
}
.py-1 {
  padding-top: calc(16rem * 0.25);
  padding-bottom: calc(16rem * 0.25);
}
.m-auto {
  margin: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.align-self-start {
  align-self: flex-start;
}
.align-self-center {
  align-self: center;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.contact-label {
  text-align: left;
  @include mq(md) {
    margin-bottom: 10rem;
  }
}
.col-2 {
  flex: 0 0 calc(2 / 12 * 100%);
  width: calc(2 / 12 * 100%);
}
.col-lg-3 {
  @include mq(lg) {
    flex: 0 0 calc(3 / 12 * 100%);
    width: calc(3 / 12 * 100%);
  }
}
.col-md-6 {
  @include mq(md) {
    flex: 0 0 calc(6 / 12 * 100%);
    width: calc(6 / 12 * 100%);
  }
}
.col-8 {
  flex: 0 0 calc(8 / 12 * 100%);
  width: calc(8 / 12 * 100%);
}
.col-lg-7 {
  @include mq(lg) {
    flex: 0 0 calc(7 / 12 * 100%);
    width: calc(7 / 12 * 100%);
  }
}
.col-md-12 {
  @include mq(md) {
    flex: 0 0 100%;
    width: 100%;
  }
}
.col-sm-10 {
  @include mq(md) {
    flex: 0 0 calc(10 / 12 * 100%);
    width: calc(10 / 12 * 100%);
  }
}
.col-sm-1 {
  @include mq(md) {
    flex: 0 0 calc(1 / 12 * 100%);
    width: calc(1 / 12 * 100%);
  }
}

.form-item {
  @extend .fs--xl;
  &__input {
    border: 2px solid #ddd;
    border-radius: 4px;
    background: #eaedf2;
    &:focus {
      border: 2px solid gray;
      outline: none;
    }
  }
}
.require-form {
  @extend .fs--l;
  background-color: #bf2a31;
  padding: 5rem;
  color: white;
  font-weight: 500;
  width: 60rem;
  @include mq(md) {
    margin-bottom: 10rem;
  }
}
</style>