<template>
  <footer class="footer">
    <a href="https://chillstack.com">
      <img src="@/assets/footer_chill_logo.svg" />
      <div>&copy; 2018- ChillStack, Inc.</div></a
    >
  </footer>
</template>

<script>
export default {
  name: "Fotter",
};
</script>

<style lang="scss" scoped>
.footer {
  height: 90rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  > a {
    height: 100%;
    display: block;
    text-decoration: none;
    border: none;
    color: #444455;
    @extend .fs--r;
    > img {
    }
  }
}
</style>
