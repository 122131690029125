<template>
  <div class="card-with-number">
    <div class="card-with-number__number">{{ number | toStringPadZero }}</div>
    <div class="card-with-number__container">
      <h3 class="card-with-number__title"><slot name="title"></slot></h3>
      <p class="card-with-number__content"><slot name="content"></slot></p>
      <square-button class="card-with-number__button" :linkTo="linkTo"
        ><slot name="button"></slot
      ></square-button>
      <div class="card-with-number__image">
        <props-image :src="image" />
      </div>
    </div>
  </div>
</template>

<script>
import SquareButton from "@/components/atoms/SquareButton.vue";
import PropsImage from "@/components/atoms/PropsImage.vue";

export default {
  components: {
    SquareButton,
    PropsImage,
  },
  props: {
    number: { type: Number, default: 0 },
    image: { type: String, default: "" },
    linkTo: { type: String, default: "" },
  },
  filters: {
    toStringPadZero: function (num) {
      if (typeof num === "number") {
        return num.toString().padStart(2, "0");
      } else if (typeof num === "string") {
        return num.padStart(2, "0");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card-with-number {
  background: #f4f5f6;
  box-shadow: 0rem 3rem 8rem #00000014;
  text-align: left;
  @include mq(md) {
    height: auto;
  }
  &__number {
    color: #444455;
    font-weight: 700;
    letter-spacing: 3rem;
    height: 59rem;
    margin-top: 10rem;
    margin-left: 20rem;
    @extend .fs--40;
  }
  &__container {
    padding: 0rem 120rem 60rem 120rem;
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 53.7473233% auto;
    flex-grow: 1;
    @include mq(lg) {
      padding: 0rem 30rem 30rem 30rem;
    }
    @include mq(md) {
      grid-template-rows: repeat(4, auto);
      grid-template-columns: auto;
    }
    @include mq(md) {
      padding: 20rem 20rem 20rem 20rem;
    }
  }
  &__title {
    grid-row: 1;
    grid-column: 1;
    height: 77rem;
    color: #000000;
    @extend .fs--26;
    line-height: 1.5;
    @include mq(xs) {
      height: auto;
    }
  }
  &__content {
    grid-row: 2;
    grid-column: 1;
    @extend .fs--r;
    line-height: 1.8;
    margin-top: 40rem;
    margin-bottom: 40rem;
    @include mq(md) {
      margin-bottom: 0rem;
    }
    @include mq(xs) {
      margin-top: 20rem;
    }
  }
  &__button {
    grid-row: 3;
    grid-column: 1;
    margin-top: auto;
    @extend .fs--14;
    @include mq(md) {
      grid-row: 4;
      justify-self: center;
      margin-top: 60rem;
      margin-bottom: 30rem;
    }
  }
  &__image {
    grid-row: 1/4;
    grid-column: 2;
    flex: 0 1 auto;
    align-self: center;
    margin-left: 50rem;
    margin-right: 8rem;
    @include mq(lg) {
      margin-left: 40rem;
    }
    @include mq(md) {
      grid-row: 3;
      grid-column: 1;
      justify-self: center;
      margin-left: 0rem;
      margin-right: 0rem;
      margin-top: 60rem;
    }
    > img {
      height: auto;
      width: 100%;
      @include mq(md) {
        width: 100%;
      }
    }
  }
}
</style>