<template>
  <div class="title-image-card" v-on:click="clickLink" :style="style">
    <div class="title-image-card__container">
      <div class="title-image-card__main">
        <div class="title-image-card__image">
          <props-image :src="image"></props-image>
        </div>
        <underline-title class="title-image-card__main__title">
          <slot name="title"></slot>
        </underline-title>
        <div class="title-image-card__main__text">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PropsImage from "@/components/atoms/PropsImage.vue";
import UnderlineTitle from "@/components/atoms/UnderlineTitle.vue";

export default {
  components: { UnderlineTitle, PropsImage },
  props: {
    image: { type: String, require: true },
    link: { type: String, default: "" },
  },
  computed: {
    style() {
      return this.link !== ""
        ? {
          cursor: "pointer",
        }
        : {};
    },
  },
  methods: {
    clickLink() {
      if (this.link !== "") {
        window.open(this.link, "_blank");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title-image-card {
  background: #ffffff;
  box-shadow: 0rem 3rem 6rem #88888814;
  padding: 42rem;
  width: 50%;

  @include mq(md) {
    width: 100%;
  }

  @include mq(sm) {
    padding: 30rem;
  }

  @include mq(xs) {
    height: auto;
  }

  &__container {
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: row-reverse;
  }

  &__main {
    align-self: flex-start;
    justify-content: space-between;
    text-align: left;

    @include mq(xs) {
      flex: 0 0 100%;
    }

    &__title {
      @extend .fs--xl;
      color: #444455;
      font-weight: 700;
    }

    &__text {
      color: #444455;
      @extend .fs--r;
      line-height: 1.866666667;
      letter-spacing: 0.75rem;
      white-space: pre-wrap;
      word-wrap: break-word;
      margin-top: 30rem;

      @include mq(sm) {
        margin-top: 15rem;
      }

      @include mq(xs) {
        margin-top: 20rem;
      }
    }
  }

  &__image {
    aspect-ratio: 16 / 9;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq(md) {
      margin-right: 0rem;
      margin-left: 0rem;
      margin-top: 0rem;
      margin-bottom: 20rem;
      width: 100%;
    }

    @include mq(xs) {
      height: auto;
    }

    >img {
      object-fit: cover;
      margin: auto;
      width: 100%;
      max-height: 100%;
      text-align: center;
    }
  }
}
</style>