<template>
  <section class="about">
    <Frame class="about__container">
      <section-title class="about__title">About<template v-slot:subtitle>Stena Gameでできること</template></section-title>
      <text-with-image class="about__item" v-for="(about, i) in abouts" :key="i" :image="about.image"
        :imageWidth="about.imageWidth" :imagePosition="about.imagePosition"><template v-slot:title>{{ about.title
        }}</template>{{ about.text }}</text-with-image>
      <div class="about__benefit">
        <h2 class="about__benefit__title">低コストでゲーム運営を効率化！</h2>
        <div class="about__benefit__benefits">
          <vertical-card class="about__benefit__benefits__benefit" v-for="(benefit, i) in benefits" :key="i"
            :image="benefit.image"><template v-slot:title>{{ benefit.title }}</template>{{ benefit.content }}
          </vertical-card>
        </div>
      </div>
    </Frame>
  </section>
</template>

<script>
import SectionTitle from "@/components/atoms/SectionTitle.vue";
import Frame from "@/components/contents/Frame.vue";
import TextWithImage from "@/components/molecules/TextWithImage.vue";
import VerticalCard from "@/components/molecules/VerticalCard.vue";

export default {
  components: {
    Frame,
    TextWithImage,
    SectionTitle,
    VerticalCard,
  },
  data: function () {
    return {
      abouts: [
        {
          title: `『発見から対処まで』
不正検知フローを自動化`,
          text: `ゲームログを入力することで、行動分析AIエンジンがユーザの行動を全て自動で解析してチートやBotなどの不正行為を行うユーザを発見します。 
検知結果は毎日レポーティングされ、Webからいつでも確認することができます。`,
          image: "about_dataflow.svg",
          imageWidth: "636rem",
          imagePosition: "left",
        },
        {
          title: `『自社タイトルに特化』
見つけたい不正を確実に検知`,
          text: `Stena Gameは各不正項目の検知に特化したAIモデルを複数搭載したプラットフォームです。
検知モデルはAIセキュリティのスペシャリストがオーダーメイドで作成し、お客様の運営タイトルに最適な形でチューニングを行います。
検知モデルは後からでも追加・削除が可能なため、リスクと予算に合わせて検知項目をご調整いただけます。`,
          image: "about_model.svg",
          imageWidth: "535rem",
          imagePosition: "right",
        },
      ],
      detectionSamples: [
        "無限レベルアップ",
        "アイテム増殖",
        "オートプレイ",
        "RMT用アカウント",
        "ステータス操作",
        "ダメージ補正",
        "位置偽装",
        "煽りプレイ",
        "オートエイム",
        "ステージ開放",
        "無限ガチャ",
        "ランキング操作",
      ],
      benefits: [
        {
          title: "不監視の工数削減",
          image: "about_reduce_resource.svg",
          content: `ユーザ行動の監視や膨大なログをひとつひとつ確認する必要がなくなり、不正発見にかかる工数を劇的に削減することができます。

さらに、高精度の不正検知技術で人手による不正対策チームでも調査しきれない不正を検知しています。`,
        },
        {
          title: "不正ユーザの自動対処",
          image: "about_mal_warning.svg",
          content: `検知結果取得APIを使用して、不正ユーザに自動で警告メッセージを表示する機能や、利用制限をかける機能の追加に便利な情報を簡単に得ることができます。

不正度が90%以上のユーザにのみ自動対処をするなど、誤BANのリスクを減らして明らかに不正と見なせるユーザのみを対処することができます。`,
        },
        {
          title: "運営KPIの汚染防止",
          image: "about_kpi.svg",
          content: `不正プレイによりゲームを攻略されることでステージクリア率などの運営KPIが不当に高く観測されてしまうなど、レベルデザインを調整する上でノイズとなる不正ユーザの行動記録を取り除くことができます。

ゲーム内イベントなどの施策効果を正確に観測するためのクレンジングとしてご利用いただけます。`,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.about {
  background: #f2f3f6;

  &__title {
    margin-bottom: 60rem;
  }

  &__detection-sample {
    width: 1140rem;
    margin: auto;
  }

  &__item {
    margin-bottom: 120rem;

    @include mq(md) {
      margin-bottom: 120rem;
    }

    &:last-child {
      margin-bottom: 60rem;
    }
  }

  &__benefit {
    margin-top: 120rem;

    &__title {
      @extend .fs--xxxl;
      text-align: center;
      letter-spacing: 1.7rem;
      color: #000000;
    }

    &__benefits {
      display: flex;
      justify-content: space-between;
      margin-top: 60rem;

      @include mq(md) {
        flex-direction: column;
      }

      &__benefit {
        @include mq(lg) {
          width: 300rem;
          padding: 20rem 20rem;
        }

        @include mq(md) {
          width: 100%;
          padding: 30rem 40rem;
          height: auto;
          margin-bottom: 30rem;
        }
      }
    }
  }
}
</style>