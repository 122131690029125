<template>
  <h3 class="underline-title" :style="styles">
    <slot></slot>
  </h3>
</template>

<script>
export default {
  props: {
    textAlign: { type: String, default: "left" },
    fontSize: { type: String, default: "26rem" },
  },
  computed: {
    styles() {
      return { fontSize: this.fontSize, textAlign: this.textAlign };
    },
  },
};
</script>

<style scoped lang="scss">
.underline-title {
  @extend .fs--xl;
  letter-spacing: 1.1rem;
  color: #444455;
  padding-bottom: 12rem;
  height: auto;
  border-bottom: 1px solid #c1c1c1;
}
</style>